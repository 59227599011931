
import { defineComponent, onMounted, ref, computed, watchEffect } from "vue";
import { Apollo } from "@/core/services";
import { GET_ABILITIES } from "../../graphql/Queries";
import { GET_PERMISSION_GROUP } from "../../permission-groups/graphql/Queries";
import { useI18n } from "vue-i18n";
export default defineComponent({
	name: "Permission",
	components: {},

	setup(props, context) {
		const selectedAbilities = ref([]) as Record<any, any>;
		const permissionGroups = ref([]) as Record<any, any>;
		const permissions = ref([]) as Record<any, any>;
		const systemLocale = ref();
		const i18n = useI18n();

		const getAbilities = () => {
			Apollo.watchQuery({
				query: GET_ABILITIES,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
			}).subscribe(({ data, errors }) => {
				permissions.value = JSON.parse(data.abilities);
			});
		};

		const getPermissionGroup = () => {
			Apollo.watchQuery({
				query: GET_PERMISSION_GROUP,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
			}).subscribe(({ data, errors }) => {
				permissionGroups.value = data.permission_groups.data;
			});
		};

		const abilities = computed(() => {
			const permissionGroupList = [] as Record<any, any>;
			if (permissionGroups.value.length > 0) {
				permissionGroups.value.forEach((element) => {
					const permission = [] as Record<any, any>;
					permissions.value.forEach((ele) => {
						if (ele.permission_group_id == element.id) {
							permission.push({
								id: ele.id,
								name: ele.title,
								value: ele.name,
							});
						}
					});

					permissionGroupList.push({
						name: element.name,
						id: element.id,
						abilities: permission,
					});
				});
			}
			return permissionGroupList;
		});

		const assignPermission = (permissions) => {
			const inputs: any = document.querySelectorAll(".parent-checkbox");
			for (let i = 0; i < inputs.length; i++) {
				inputs[i].checked = false;
			}

			if (permissions.length > 0) {
				setTimeout(function() {
					permissions.forEach((element) => {
						const domClick = document.getElementById(element) as Record<any, any>;
						if (domClick) {
							domClick.click();
						}
					});
				}, 500);
			}
		};

		const groupSelectedHandler = (event, abilities) => {
			if (event.target.checked == true) {
				abilities.forEach((element) => {
					const index = selectedAbilities.value.findIndex((ele) => ele == element.value);
					index == -1 ? selectedAbilities.value.push(element.value) : null;
				});
			} else {
				abilities.forEach((element) => {
					const index = selectedAbilities.value.findIndex((ele) => ele == element.value);
					index != -1 ? selectedAbilities.value.splice(index, 1) : "";
				});
			}

			context.emit("permissions", selectedAbilities.value);
		};

		const singleSelectHandler = (event, abilities, ability, parent_id, parent_index) => {
			if (event.target.checked == true) {
				let childCounter = 0;
				selectedAbilities.value.push(ability);
				abilities.forEach((element) => {
					selectedAbilities.value.forEach((ele) => {
						if (element.value == ele) {
							childCounter++;
						}

						if (abilities.length == childCounter) {
							(document.getElementById(`${parent_id}_${parent_index}`) as Record<any, any>).checked = true;
						}
					});
				});
			} else {
				const index = Object.values(selectedAbilities.value).findIndex((ele) => ele == ability);
				index != -1 ? selectedAbilities.value.splice(index, 1) : "";
				(document.getElementById(`${parent_id}_${parent_index}`) as Record<any, any>).checked = false;
			}

			context.emit("permissions", selectedAbilities.value);
		};

		onMounted(() => {
			getPermissionGroup();
			getAbilities();
		});
		watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
		});

		return {
			abilities,
			selectedAbilities,
			groupSelectedHandler,
			singleSelectHandler,
			assignPermission,
			systemLocale
		};
	},
});
